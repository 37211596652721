

    .ui.modal>.content{
    
        padding: 2rem !important;
        color: #575764;
        font-size:1.4rem;
        font-family: 'Heebo', 'Times New Roman', Times, serif;
        font-weight: bold;
        
    }