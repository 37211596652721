.nav-bar {
  height: 100%;
  top: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  border-style:none;

  @include tablet{
    padding: 4rem 5rem ;
  }

  @include desktop{
    padding: 4rem 15rem ;
  }

  a{
    font-family: 'Crete Round', 'Times New Roman', Times, serif;
    font-weight: 100;
    color: #5877E5;
    font-size: 2rem;
    z-index: 1000;
  }

}

.auth.nav-bar{
  background: #5877E6;
  border: none;
  a{
    color:#FFFFFF;
  }
}