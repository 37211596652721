.event-menu-container{

    width: 12vw;
    @include tablet{
        width: 12%;
    }

    @include desktop{
        width: 6%;
    }
    
    img{
        width: 100%
    }
    .event-menu{
        border: 1px solid rgba( 0, 0, 0, .5);
        background: #575764;
        opacity: .95;
        position: absolute;
        top:0;
        right: 1px;       
        height: 26vh;
        width: 42%;
        z-index: 10;
        transition: opacity .5s ease-in, 
            height .5s ease-in-out,
            width .5s ease-in-out;
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        overflow: hidden;

        @include tablet{

            right:5rem;
            width:25%;
            height:90%;
        }

        @include desktop{
            right: 15rem;
            height: 85%;
            width: 20%;
        }

        i.icon{
            align-self: flex-end;
            margin: 2rem 1.6rem 1rem 0;
            height: 2rem;
            width: 2rem;
            color: #F6F9FC;
    
        }
        :nth-child(2){
            display: flex;
            flex-direction: column;
            height:70%;
            margin-top: 2rem;
            align-items: flex-end;
            a, p{
                
                max-width: fit-content ;
                text-align: right;
                color: #F6F9FC;
                font-size: 1.4rem;
                font-weight: bold;
                font-family: 'Heebo', 'Times New Roman', Times, serif;
                margin-right: 2.2rem ;
                margin-bottom: 1rem;
                padding-bottom: .2rem;
            }
            
        }


    }
    .invisible{
        opacity: 0;
        transform: translateX(0);
        height: 0px;
        width: 0px;
    }
}