@import url(https://fonts.googleapis.com/css?family=Crete+Round|Heebo&display=swap);
html {
  font-size: 62.5% !important; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: 'Heebo', 'Times New Roman', Times, serif; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white; }

.App-link {
  color: #61dafb; }

.content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
  background: #fff; }

.strikethrough {
  text-decoration: line-through; }

.hide {
  display: none; }

.dashboard-event {
  height: 100%;
  position: relative;
  padding: 3.2rem 0 1rem 0;
  font-family: 'Heebo', 'Times New Roman', Times, serif;
  width: 100vw;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #a5a6b5; }
  @media (min-width: 768px) {
    .dashboard-event {
      padding: 0;
      padding: 5rem 0; } }
  .dashboard-event .event-info {
    display: flex;
    width: 100vw;
    height: 20rem;
    justify-content: space-between;
    position: relative; }
    @media (min-width: 768px) {
      .dashboard-event .event-info {
        width: 100%; } }
    @media (min-width: 1068px) {
      .dashboard-event .event-info {
        height: 22rem; } }
    .dashboard-event .event-info .event-left_side {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 50%; }
      @media (min-width: 768px) {
        .dashboard-event .event-info .event-left_side {
          width: 45%; } }
      .dashboard-event .event-info .event-left_side p {
        color: #fff;
        width: 100%;
        padding: .8rem 0;
        border-radius: 0 20px 20px 0;
        text-align: left;
        padding-left: 1.2rem;
        font-size: 1.6rem; }
        @media (min-width: 768px) {
          .dashboard-event .event-info .event-left_side p {
            width: 95%;
            padding: 1.2rem 0;
            padding-left: 5rem; } }
        @media (min-width: 1068px) {
          .dashboard-event .event-info .event-left_side p {
            width: 80%;
            padding-left: 15rem; } }
      .dashboard-event .event-info .event-left_side :nth-child(2) {
        font-size: 1.2rem;
        height: auto;
        position: absolute;
        top: 5rem;
        text-align: left;
        margin-left: 1.2rem;
        font-family: 'Heebo', 'Times New Roman', Times, serif;
        font-size: 1.8rem;
        color: #575764; }
        @media (min-width: 768px) {
          .dashboard-event .event-info .event-left_side :nth-child(2) {
            margin-left: 5rem; } }
        @media (min-width: 1068px) {
          .dashboard-event .event-info .event-left_side :nth-child(2) {
            margin-left: 15rem;
            top: 7rem; } }
      .dashboard-event .event-info .event-left_side :nth-child(3) {
        position: absolute;
        top: 10rem;
        text-align: left;
        margin-left: 1.2rem;
        font-size: 1.8rem;
        color: #575764; }
        @media (min-width: 768px) {
          .dashboard-event .event-info .event-left_side :nth-child(3) {
            margin-left: 5rem; } }
        @media (min-width: 1068px) {
          .dashboard-event .event-info .event-left_side :nth-child(3) {
            margin-left: 15rem;
            top: 14rem; } }
    .dashboard-event .event-info .event-right_side {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      width: 50vw;
      height: 100%;
      margin-right: 1.2rem; }
      @media (min-width: 768px) {
        .dashboard-event .event-info .event-right_side {
          margin-right: 5rem; } }
      @media (min-width: 1068px) {
        .dashboard-event .event-info .event-right_side {
          margin-right: 15rem; } }
      .dashboard-event .event-info .event-right_side .calendar {
        position: absolute;
        top: 6.2rem;
        height: 9rem;
        border: 1px solid #E3696A;
        background: #ffffff;
        border-radius: 15px;
        width: 38%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        -webkit-filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.15));
                filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.15)); }
        @media (min-width: 768px) {
          .dashboard-event .event-info .event-right_side .calendar {
            width: 25%;
            height: 10rem; } }
        @media (min-width: 1068px) {
          .dashboard-event .event-info .event-right_side .calendar {
            width: 20%;
            height: 12rem; } }
        .dashboard-event .event-info .event-right_side .calendar :nth-child(1) {
          color: #E3696A;
          font-size: 1.8rem;
          text-align: center;
          margin: auto 0;
          font-weight: bold; }
        .dashboard-event .event-info .event-right_side .calendar span {
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #E3696A; }
        .dashboard-event .event-info .event-right_side .calendar :nth-child(3) {
          color: #E3696A;
          text-align: center;
          font-size: 2.4rem;
          margin: auto 0;
          font-weight: bold; }
    .dashboard-event .event-info .expand-arrow {
      height: 2rem;
      width: 2rem;
      position: absolute;
      bottom: 1.2rem;
      left: 0;
      right: 0;
      margin: .5rem auto; }
  .dashboard-event .event-meta {
    text-align: left;
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    height: 100%;
    width: 100vw;
    opacity: 1;
    transform: translateX(0);
    transition: opacity .35s ease-in-out, height .35s ease-in-out, width .35s ease-in-out, transform .35s ease-in-out; }
    @media (min-width: 768px) {
      .dashboard-event .event-meta {
        overflow: hidden;
        margin: 0;
        flex-direction: row;
        width: 100%;
        transition: opacity 0.3s cubic-bezier(0.41, 0.4, 0.93, 0.28), height 0.3s cubic-bezier(0.41, 0.4, 0.93, 0.28), transform 0.3s cubic-bezier(0.41, 0.4, 0.93, 0.28), width 0.3s cubic-bezier(0.41, 0.4, 0.93, 0.28); } }
    .dashboard-event .event-meta h4 {
      color: rgba(70, 76, 86, 0.55);
      font-size: 1.4rem;
      font-weight: 800; }
    .dashboard-event .event-meta .event-info-extra {
      margin-top: -3rem; }
      @media (min-width: 768px) {
        .dashboard-event .event-meta .event-info-extra {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-around;
          align-items: baseline; } }
      @media (min-width: 1068px) {
        .dashboard-event .event-meta .event-info-extra {
          margin: 0;
          padding: 0;
          padding: 7.5rem 15rem 7.5rem 15rem; } }
      .dashboard-event .event-meta .event-info-extra p {
        font-size: 1.8rem;
        margin-top: -.5rem;
        padding-bottom: 1.2rem; }
        @media (min-width: 768px) {
          .dashboard-event .event-meta .event-info-extra p {
            padding: 0;
            margin: 0; } }
      .dashboard-event .event-meta .event-info-extra :nth-child(1) {
        margin-top: 2rem; }
        @media (min-width: 768px) {
          .dashboard-event .event-meta .event-info-extra :nth-child(1) {
            margin: 0; } }
      .dashboard-event .event-meta .event-info-extra :nth-last-child(1) {
        padding-bottom: 0;
        margin-bottom: 2rem; }
        @media (min-width: 768px) {
          .dashboard-event .event-meta .event-info-extra :nth-last-child(1) {
            margin: 0; } }
      @media (min-width: 768px) {
        .dashboard-event .event-meta .event-info-extra .data-container {
          margin: 0;
          padding: 0;
          padding: 3rem 5rem 2rem 5rem; } }
      @media (min-width: 1068px) {
        .dashboard-event .event-meta .event-info-extra .data-container {
          margin: 0;
          padding: 0; } }
      .dashboard-event .event-meta .event-info-extra .expand-arrow {
        display: block;
        height: 2rem;
        width: 2rem;
        bottom: -2rem;
        margin: 0 auto 1.2rem auto; }
        @media (min-width: 768px) {
          .dashboard-event .event-meta .event-info-extra .expand-arrow {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1rem; } }
  .dashboard-event .progress-bar-container {
    border-top: 1px solid #D1CEE1;
    border-bottom: 1px solid #D1CEE1;
    padding: 1.6rem 1.2rem; }
    @media (min-width: 768px) {
      .dashboard-event .progress-bar-container {
        padding: 3rem 5rem; } }
    @media (min-width: 1068px) {
      .dashboard-event .progress-bar-container {
        padding: 5rem 15rem; } }
    .dashboard-event .progress-bar-container .progress-bar h4 {
      color: #B3B1C2; }
      @media (min-width: 768px) {
        .dashboard-event .progress-bar-container .progress-bar h4 {
          padding-bottom: 1.2rem; } }
    .dashboard-event .progress-bar-container .progress-bar .budget-indicator {
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      font-weight: 800;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      transform: translateY(-155%);
      color: #6A6D72;
      margin-right: 20px; }
  .dashboard-event .container-lists {
    height: 11rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    @media (min-width: 768px) {
      .dashboard-event .container-lists {
        height: 10rem;
        padding: 3rem 5rem; } }
    @media (min-width: 1068px) {
      .dashboard-event .container-lists {
        padding: 5rem 15rem; } }
    .dashboard-event .container-lists h4 {
      text-align: left;
      margin: 1.2rem 0 0 1.2rem;
      font-size: 1.2rem;
      color: #B3B1C2; }
      @media (min-width: 768px) {
        .dashboard-event .container-lists h4 {
          padding-top: 1.8rem; } }
    .dashboard-event .container-lists .lists {
      width: 100%;
      display: flex;
      justify-content: space-around; }
      @media (min-width: 768px) {
        .dashboard-event .container-lists .lists {
          padding-top: 2.4rem;
          justify-content: space-between; } }
      .dashboard-event .container-lists .lists button {
        width: 44%;
        border: 1px solid #6A6D72;
        border-radius: 20px;
        background-color: transparent;
        font-size: 1.6rem;
        color: #6A6D72; }
        @media (min-width: 1068px) {
          .dashboard-event .container-lists .lists button {
            width: 25%; } }
  .dashboard-event .invisible {
    opacity: 0;
    height: 0px;
    width: 0px;
    transform: translateX(-100%); }
    @media (min-width: 768px) {
      .dashboard-event .invisible {
        width: 100%; } }

.ui.modal > .content {
  padding: 2rem !important;
  color: #575764;
  font-size: 1.4rem;
  font-family: 'Heebo', 'Times New Roman', Times, serif;
  font-weight: bold; }

.add-event {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .add-event h2 {
    width: 100%;
    height: 7.7rem;
    padding: 2.4rem 1.2rem;
    font-family: 'Crete Round', 'Times New Roman', Times, serif;
    display: flex;
    width: 100vw;
    height: 7.7rem;
    justify-content: space-between;
    align-items: center;
    background: white;
    background: linear-gradient(180deg, white 0%, white 62%, #efefef 100%);
    color: #7992EA;
    font-size: 2.2rem;
    font-weight: 500; }
    @media (min-width: 768px) {
      .add-event h2 {
        padding: 2.4rem 5rem; } }
    @media (min-width: 1068px) {
      .add-event h2 {
        padding: 2.4rem 15rem; } }
  .add-event form {
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    @media (min-width: 768px) {
      .add-event form {
        padding: 0 5rem; } }
    @media (min-width: 1068px) {
      .add-event form {
        padding: 3rem 50rem; } }
    .add-event form .field {
      padding: 1.6rem 1.2rem;
      display: flex;
      flex-direction: column; }
      .add-event form .field label {
        font-size: 1.4rem;
        color: rgba(70, 76, 86, 0.55);
        font-weight: bold; }
      .add-event form .field input {
        height: 3rem;
        border: none;
        border-bottom: 1px solid rgba(153, 157, 162, 0.3); }
    .add-event form .time :nth-child(2) select {
      color: rgba(70, 76, 86, 0.55);
      border: none;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      padding: .2rem .4rem;
      margin-top: .5rem;
      margin-right: 2rem; }
    .add-event form #bg-color :nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      justify-content: space-evenly;
      width: 85%;
      height: 100%;
      margin-top: 1.6rem;
      margin: 0 auto; }
      @media (min-width: 768px) {
        .add-event form #bg-color :nth-child(2) {
          width: 90%;
          justify-content: space-between; } }
      @media (min-width: 1068px) {
        .add-event form #bg-color :nth-child(2) {
          width: 95%; } }
      .add-event form #bg-color :nth-child(2) .bgcolor {
        width: 15%;
        margin: 5%;
        height: 4.5rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        cursor: pointer; }
        @media (min-width: 768px) {
          .add-event form #bg-color :nth-child(2) .bgcolor {
            margin: 5% 0;
            width: 8%;
            height: 5rem; } }
        @media (min-width: 1068px) {
          .add-event form #bg-color :nth-child(2) .bgcolor {
            margin: 5% 1%;
            width: 4.5rem;
            height: 4.5rem; } }
    .add-event form #publicity {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .add-event form #publicity :nth-child(1) {
        margin-right: 1rem;
        height: 1.8rem;
        width: 1.8rem; }
    .add-event form .location-search-input {
      width: 100%; }
    .add-event form .suggestion-item {
      padding-top: 1.2rem; }
      .add-event form .suggestion-item span {
        color: rgba(70, 76, 86, 0.8);
        font-size: 1.4rem;
        font-weight: bold; }
    .add-event form button {
      width: 90%;
      border: none;
      background: #5078B5;
      border-radius: 15px;
      height: 5rem;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      color: #fff;
      margin: 1.8rem auto; }

.list-page-container {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.2rem; }
  @media (min-width: 768px) {
    .list-page-container {
      padding: 1.2rem 5rem; } }
  @media (min-width: 1068px) {
    .list-page-container {
      padding: 1.2rem 15rem; } }
  .list-page-container p,
  .list-page-container h2 {
    font-family: 'Heebo', 'Times New Roman', Times, serif;
    height: 1.6rem; }
  .list-page-container #header {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: center;
    height: 6rem;
    margin-bottom: 1.2rem; }
    .list-page-container #header img {
      width: 1.6rem;
      margin-right: 1.8rem; }
    .list-page-container #header p {
      width: 80%;
      margin: auto 0;
      font-weight: bold;
      color: #B3B1C2; }
    .list-page-container #header h2 {
      font-size: 1.6rem;
      color: #575764; }
  .list-page-container .items-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 1.2rem 0; }
    @media (min-width: 768px) {
      .list-page-container .items-container {
        margin: 0 0 1.2rem 0; } }
    .list-page-container .items-container #list-subtotal {
      height: 4.2rem;
      width: 70%;
      background: #CAC9D5;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (min-width: 768px) {
        .list-page-container .items-container #list-subtotal {
          width: 55%; } }
      .list-page-container .items-container #list-subtotal p {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 1.3rem; }
    .list-page-container .items-container .items {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 1.2rem 0;
      position: relative; }
      @media (min-width: 768px) {
        .list-page-container .items-container .items {
          padding: 2rem 0; } }
      .list-page-container .items-container .items .item-container {
        padding: 1.2rem 0;
        display: flex;
        border-bottom: 1px solid rgba(209, 206, 225, 0.4);
        align-items: center;
        position: relative; }
        @media (min-width: 768px) {
          .list-page-container .items-container .items .item-container {
            padding: 1.8rem 0; } }
        .list-page-container .items-container .items .item-container p {
          margin: auto 0; }
        .list-page-container .items-container .items .item-container :nth-child(2) {
          margin-left: 1.2rem; }
        .list-page-container .items-container .items .item-container :nth-child(3) {
          position: absolute;
          right: 6rem; }
        .list-page-container .items-container .items .item-container .icon {
          position: absolute;
          right: 1rem; }
        .list-page-container .items-container .items .item-container .edit-item-cost {
          right: 0; }
          .list-page-container .items-container .items .item-container .edit-item-cost input {
            width: 50%;
            transform: translate(-10%, 10%);
            border: none;
            border: 1px solid #575764;
            font-family: 'Heebo', 'Times New Roman', Times, serif;
            font-size: 1.4rem;
            font-weight: bold;
            padding: 0.2rem .6rem; }
          .list-page-container .items-container .items .item-container .edit-item-cost button {
            border: none;
            border-radius: 2px;
            padding: .6rem;
            width: 8rem;
            font-size: 1.3rem;
            font-family: 'Heebo', 'Times New Roman', Times, serif;
            background: #575764;
            color: #F6F9FC;
            position: absolute;
            right: 0; }
  .list-page-container #update-button {
    width: 50%;
    height: 3.4rem;
    border: none;
    border-radius: 2px;
    padding: .6rem;
    font-size: 1.4rem;
    font-family: 'Heebo', 'Times New Roman', Times, serif;
    background: #575764;
    color: #F6F9FC; }
  .list-page-container .progress-bar {
    padding: 2rem 0; }
    .list-page-container .progress-bar p {
      padding-top: 1.2rem; }
  .list-page-container #form_container {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    padding: 1.2rem 0;
    position: relative; }
    .list-page-container #form_container p {
      width: 80%;
      color: #575764;
      font-weight: bold; }
    .list-page-container #form_container i.icon {
      position: absolute;
      right: 0;
      top: 0; }
    .list-page-container #form_container .list-form-container {
      width: 100%;
      height: 13rem;
      opacity: 1;
      transform: translateX(0%);
      transition: transform .55s ease-in-out, opacity .55s ease-in-out, height .45s ease-in-out, width .45s ease-in-out; }
      .list-page-container #form_container .list-form-container .list-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 1.2rem 0;
        justify-content: space-between; }
        .list-page-container #form_container .list-form-container .list-form h2 {
          width: 100%;
          padding-bottom: 1.2rem; }
        .list-page-container #form_container .list-form-container .list-form .input-section {
          width: 45%;
          display: flex;
          flex-direction: column; }
          .list-page-container #form_container .list-form-container .list-form .input-section label {
            color: #575764;
            font-weight: bold;
            padding-bottom: .6rem; }
        .list-page-container #form_container .list-form-container .list-form button {
          margin-top: 1.2rem;
          width: 45%;
          height: 3.4rem;
          border: none;
          border-radius: 2px;
          padding: .6rem;
          font-size: 1.4rem;
          font-family: 'Heebo', 'Times New Roman', Times, serif;
          background: #575764;
          color: #F6F9FC; }
        .list-page-container #form_container .list-form-container .list-form .todo-form-input {
          width: 100%; }
          .list-page-container #form_container .list-form-container .list-form .todo-form-input label {
            margin-bottom: .3rem; }
        .list-page-container #form_container .list-form-container .list-form .todo-form-btn {
          width: 40%;
          margin-top: 1.2rem; }
    .list-page-container #form_container .invisible {
      transform: translateX(-150%);
      opacity: 0;
      height: 0px;
      width: 0px;
      transition: transform 1s ease-out; }
  .list-page-container .container-lists {
    height: 11rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
    .list-page-container .container-lists h4 {
      text-align: left;
      margin: 1.2rem 0 0 1.2rem;
      font-size: 1.2rem;
      color: #B3B1C2; }
    .list-page-container .container-lists .lists {
      width: 100%;
      display: flex;
      justify-content: space-around; }
      .list-page-container .container-lists .lists button {
        width: 44%;
        border: 1px solid #6A6D72;
        border-radius: 20px;
        background-color: transparent;
        font-size: 1.6rem;
        color: #6A6D72; }

.event-menu-container {
  width: 12vw; }
  @media (min-width: 768px) {
    .event-menu-container {
      width: 12%; } }
  @media (min-width: 1068px) {
    .event-menu-container {
      width: 6%; } }
  .event-menu-container img {
    width: 100%; }
  .event-menu-container .event-menu {
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #575764;
    opacity: .95;
    position: absolute;
    top: 0;
    right: 1px;
    height: 26vh;
    width: 42%;
    z-index: 10;
    transition: opacity .5s ease-in,  height .5s ease-in-out, width .5s ease-in-out;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    overflow: hidden; }
    @media (min-width: 768px) {
      .event-menu-container .event-menu {
        right: 5rem;
        width: 25%;
        height: 90%; } }
    @media (min-width: 1068px) {
      .event-menu-container .event-menu {
        right: 15rem;
        height: 85%;
        width: 20%; } }
    .event-menu-container .event-menu i.icon {
      align-self: flex-end;
      margin: 2rem 1.6rem 1rem 0;
      height: 2rem;
      width: 2rem;
      color: #F6F9FC; }
    .event-menu-container .event-menu :nth-child(2) {
      display: flex;
      flex-direction: column;
      height: 70%;
      margin-top: 2rem;
      align-items: flex-end; }
      .event-menu-container .event-menu :nth-child(2) a, .event-menu-container .event-menu :nth-child(2) p {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        text-align: right;
        color: #F6F9FC;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: 'Heebo', 'Times New Roman', Times, serif;
        margin-right: 2.2rem;
        margin-bottom: 1rem;
        padding-bottom: .2rem; }
  .event-menu-container .invisible {
    opacity: 0;
    transform: translateX(0);
    height: 0px;
    width: 0px; }

.events-content {
  max-width: 100vw;
  overflow: hidden; }
  .events-content h2 {
    margin: 0; }
  .events-content .no-events {
    text-align: center;
    margin: 3rem 0;
    height: 100%;
    font-size: 1.8rem; }
  .events-content .new-event {
    display: flex;
    width: 100vw;
    height: 7.7rem;
    justify-content: space-between;
    align-items: center;
    background: white;
    background: linear-gradient(180deg, white 0%, white 62%, #efefef 100%); }
    @media (min-width: 768px) {
      .events-content .new-event {
        padding: 5rem; } }
    @media (min-width: 1068px) {
      .events-content .new-event {
        padding: 0 15rem 3rem 15rem; } }
    .events-content .new-event h2 {
      margin-left: 5.53%;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      font-weight: bold;
      font-size: 1.8rem;
      color: #7992EA; }
      @media (min-width: 768px) {
        .events-content .new-event h2 {
          margin: 0; } }
    .events-content .new-event i.icon {
      padding-right: 3.8rem;
      font-size: 2.67rem;
      transform: translateY(20%);
      color: #6065D1; }
      @media (min-width: 768px) {
        .events-content .new-event i.icon {
          padding-right: 0; } }
  .events-content .search-bar {
    position: relative;
    margin: 1.6rem auto;
    width: 65%; }
    .events-content .search-bar input {
      width: 100%;
      height: 4rem;
      border: 1px solid #575764;
      border-radius: 2rem;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      color: #575764;
      padding-left: 1.2rem; }
    .events-content .search-bar i {
      position: absolute;
      right: 1.2rem;
      top: .9rem; }
  .events-content .new-event.add {
    justify-content: flex-end; }
    .events-content .new-event.add i.icon {
      margin-left: 1.2rem; }

footer {
  border-top: 1px solid rgba(240, 240, 240, 0.8);
  display: flex;
  flex-direction: column;
  background: #ffffff;
  color: #7992EA;
  bottom: 0;
  width: 100%;
  margin-top: -.1rem; }
  footer p {
    color: #7992EA;
    font-size: 1rem; }
  footer .footer-content {
    margin: 15px 0px;
    text-align: center; }

.auth.footer {
  background: #5877E6; }
  .auth.footer p {
    color: #FFFFFF; }

.register.auth.footer {
  background: #F7F7F9; }
  @media (min-width: 1068px) {
    .register.auth.footer {
      background: #5877E6; } }
  .register.auth.footer p {
    color: #B3B1C2; }
    @media (min-width: 1068px) {
      .register.auth.footer p {
        color: #FFFFFF; } }

.login-container.content {
  max-width: 100vw;
  width: 100vw;
  position: relative;
  background: #5877e6;
  border-style: none; }
  .login-container.content img {
    width: 100%; }
  .login-container.content .banderitas-container,
  .login-container.content .confetti-container {
    position: absolute;
    width: 100%; }
  .login-container.content .banderitas-container {
    top: -6rem; }
    @media (min-width: 1068px) {
      .login-container.content .banderitas-container {
        top: -9rem; } }
  .login-container.content .confetti-container {
    top: -2rem; }
    @media (min-width: 1068px) {
      .login-container.content .confetti-container {
        width: 92%; } }
  .login-container.content .form {
    width: 100%;
    margin: 14rem 0 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5.8rem 3rem 3.8rem 3rem;
    background: #f7f7f9;
    border-radius: 40px;
    z-index: 100;
    position: relative; }
    @media (min-width: 1068px) {
      .login-container.content .form {
        width: 492px;
        height: 600px;
        padding: 0;
        margin: 0;
        margin-top: 90px;
        padding: 50px 40px;
        justify-content: space-evenly; } }
    .login-container.content .form .header {
      text-align: center;
      color: #b3b1c2;
      font-size: 2.8rem;
      font-family: "Heebo", "Times New Roman", Times, serif;
      font-weight: bolder; }
      @media (min-width: 1068px) {
        .login-container.content .form .header {
          padding-top: 10px;
          font-size: 2.8rem; } }
    .login-container.content .form .ui.input {
      display: flex;
      flex-direction: column;
      height: 8rem;
      overflow: hidden; }
      .login-container.content .form .ui.input label {
        font-family: "Heebo", "Times New Roman", Times, serif;
        font-size: 16px;
        line-height: 23px;
        color: #b3b1c2; }
      .login-container.content .form .ui.input input {
        border: none;
        fill: none;
        background: transparent;
        border-bottom: 1.2px solid rgba(209, 206, 225, 0.8);
        margin-bottom: 2.4rem;
        transition: border-bottom 0.25s ease-in-out; }
        .login-container.content .form .ui.input input:focus {
          border-bottom: 1.6px solid #125ccc; }
    .login-container.content .form button {
      margin-top: 1.2rem;
      width: 100%;
      background: transparent;
      border: 1px solid #5877e5;
      border-radius: 20px;
      color: #5877e5;
      font-size: 18px;
      font-family: "Heebo", "Times New Roman", Times, serif;
      transition: color 0.25s ease-in, background 0.25s ease-in; }
      .login-container.content .form button:hover {
        color: #fff;
        background: #5877e5; }
      @media (min-width: 1068px) {
        .login-container.content .form button {
          margin-top: 3rem; } }
    .login-container.content .form .register-btn {
      background: #5877e5;
      color: #f7f7f9; }
      .login-container.content .form .register-btn:hover {
        color: #5877e5;
        background: #f7f7f9; }
    .login-container.content .form #sign-up-link {
      text-align: center;
      margin-top: 2rem;
      font-size: 1.4rem;
      color: #b3b1c2;
      font-weight: bold; }
      .login-container.content .form #sign-up-link a {
        color: #9794ac; }

.login-container.content.register .form {
  border-radius: 40px 40px 0 0;
  margin-bottom: 0; }
  @media (min-width: 1068px) {
    .login-container.content.register .form {
      border-radius: 40px; } }

.error.show {
  transform: translate(0%, -7.6rem);
  text-align: right;
  opacity: 1;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.25s cubic-bezier(0.42, 0.86, 0.47, 0.16);
  color: #e3696a; }

.error {
  transform: translate(0%, -250%);
  opacity: 0; }

.error.generic {
  transform: translate(0, 0);
  position: absolute;
  left: 30%;
  bottom: 6.6rem;
  opacity: 1;
  text-align: center;
  color: #e3696a;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  margin: 1.2rem;
  height: auto;
  bottom: 5.4rem;
  left: 30%; }
  @media (min-width: 1068px) {
    .error.generic {
      bottom: 10.6rem;
      left: 35%; } }

.login-container.content.register .error.generic {
  bottom: 6.5rem;
  left: 30%; }
  @media (min-width: 1068px) {
    .login-container.content.register .error.generic {
      bottom: 7.2rem;
      left: 35%; } }

.nav-bar {
  height: 100%;
  top: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  border-style: none; }
  @media (min-width: 768px) {
    .nav-bar {
      padding: 4rem 5rem; } }
  @media (min-width: 1068px) {
    .nav-bar {
      padding: 4rem 15rem; } }
  .nav-bar a {
    font-family: 'Crete Round', 'Times New Roman', Times, serif;
    font-weight: 100;
    color: #5877E5;
    font-size: 2rem;
    z-index: 1000; }

.auth.nav-bar {
  background: #5877E6;
  border: none; }
  .auth.nav-bar a {
    color: #FFFFFF; }

