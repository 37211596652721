@mixin flex($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;

}

@mixin tablet {
    @media(min-width: 768px) { @content; }
} 

@mixin desktop {
    @media(min-width: 1068px) { @content; }
} 
