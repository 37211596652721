footer {
    border-top: 1px solid rgba(240, 240, 240, .8);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    color: #7992EA;
    bottom: 0;
    width: 100%;
    margin-top: -.1rem;
    // z-index: 0;
    p{
        color: #7992EA;
        font-size: 1rem;
    }

    .footer-content{
        margin: 15px 0px;
        text-align: center;
    }


}

.auth.footer{

    background: #5877E6;
    p{
        color:#FFFFFF;
    }
}

.register.auth.footer{

    background: #F7F7F9;
    @include desktop{
        background: #5877E6;
    }
    p{
        color: #B3B1C2;
        @include desktop{
            color: #FFFFFF
        }
    }
}