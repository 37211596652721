.events-content{
  max-width: 100vw;
  overflow: hidden;

  h2{
    margin: 0;

    @include tablet{

    }

  }

  .no-events{
    text-align: center;
    margin: 3rem 0;
    height:100%;
    font-size:1.8rem;
  }

  .new-event{
    
    display: flex;
    width: 100vw;
    height: 7.7rem;
    justify-content: space-between;
    align-items: center;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 62%, rgba(239,239,239,1) 100%);
    @include tablet{
      padding: 5rem;
    }

    
    @include desktop{
      padding: 0 15rem 3rem 15rem;
    }

    h2{
      margin-left: 5.53%;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      font-weight: bold;
      font-size: 1.8rem;
      color: #7992EA;

      @include tablet{
        margin: 0;
      }

    }

    i.icon{
      padding-right: 3.8rem;
      font-size:2.67rem;
      transform: translateY(20%);
      color:  #6065D1;
      @include tablet{
        padding-right: 0;
      }
    }
  }

  .search-bar{
    
    position: relative;
    margin: 1.6rem auto;
    width: 65%;
    input{
      width: 100%;
      height: 4rem;
      border: 1px solid #575764;
      border-radius: 2rem;
      font-size: 1.4rem;
      font-weight: bold;
      font-family: 'Heebo', 'Times New Roman', Times, serif;
      color: #575764;
      padding-left: 1.2rem;
    }
    i{
      position: absolute;
      right: 1.2rem;
      top: .9rem;
    }

  }

  
  .new-event.add{
    justify-content: flex-end;
    i.icon{
      margin-left: 1.2rem;
      
    }
  }
  

}
