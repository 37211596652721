//primary colors
$primary-blue1: #125ccc;
$primary-blue2: #073c8a;
$primary-blue3: #a9cbff;

$primary-gray1: #5d78a1;
$primary-gray2: #d2ddee;


//accent colors
$accent-purple1: #7d69f8;
$accent-purple2: #a7a5fb;
$accent-purple3: #ebe5fa;


//neutral colors

$neutral-lightBlue: #ebf1fa;
$neutral-darkBlue: rgba(88, 92, 206, 0.95);
$neutral-black: #061224;

